export const onInitialClientRender = () => {
  window.scrollTo(0, 0);
  document.body.classList.add('nav-open');
  const main = document.querySelector('.main-fade');
  main.style.opacity = 0;
  setTimeout(() => {
    document.body.classList.remove('nav-open');
    document.getElementById('___loader').style.display = 'none';
    main.style.opacity = 1;
  }, 800);
};
